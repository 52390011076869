window.dataLayer = window.dataLayer || [];

// -----------------------------------------------------------------------------
// Show unsupported browser info and stop execution
// -----------------------------------------------------------------------------
const isIE = /Trident|MSIE/.test(navigator.userAgent)

if( isIE) {
  document.getElementById( 'header' ).style.display = 'none'
  document.getElementById( 'main' ).style.display = 'none'
  document.getElementById( 'footer' ).style.display = 'none'

  document.getElementById( 'useragent' ).style.display = 'flex'
  throw new Error("IE not supported")
}

// -----------------------------------------------------------------------------
// Show cookie banner
// -----------------------------------------------------------------------------
if( !localStorage.getItem( 'consent' ) ) {
  document.querySelector( '.cookie-banner' ).classList.remove( 'hidden' )
}

const isMobileDevice = () => {
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

// -----------------------------------------------------------------------------
// Accept cookies
// -----------------------------------------------------------------------------
function acceptCookies() {
  localStorage.setItem( 'consent', JSON.stringify( { 'val': true, 'date': new Date().toISOString() } ) )
  document.querySelector( '.cookie-banner' ).classList.add( 'hidden' )

  if( isMobileDevice() ) {
    window.removeEventListener( 'touchstart', acceptCookies )
  } else {
    window.removeEventListener( 'scroll', acceptCookies )
    window.removeEventListener( 'click', acceptCookies )
  }

  // GTM event
  window.dataLayer.push( { 'event': 'cookieConsent' } )
}

if( !localStorage.getItem( 'consent' ) ) {

  if( isMobileDevice() ) {
    window.addEventListener( 'touchstart', acceptCookies )
  } else {
    window.addEventListener( 'scroll', acceptCookies )
    window.addEventListener( 'click', acceptCookies )
  }

} else {
  // GTM event
  window.dataLayer.push( { 'event': 'cookieConsent' } )
}

// -----------------------------------------------------------------------------
// Disable install prompt
// -----------------------------------------------------------------------------
window.addEventListener( 'beforeinstallprompt', (event) => {
  if( location.pathname == '/' ) {
    event.preventDefault()
  }
});


// -----------------------------------------------------------------------------
// Toggle mobile menu/nav
// -----------------------------------------------------------------------------
const menu         = document.querySelector( '.mobile-menu' )
const toggleInputs = Array.from( document.querySelectorAll( '.toggle' ) )

const toggleMenu = () => {
  menu.classList.toggle( 'hidden' )
  toggleInputs.forEach( ( t ) => {
    t.classList.toggle( 'hidden' )
  } )

}

toggleInputs.forEach( ( t ) => {
  t.addEventListener( 'click', toggleMenu )
} )
